<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalEditarFormaPagamento = true"
    ></EditButton>
    <SideBar
      title="Editar formaPagamento"
      @hide="cancelar"
      :active="modalEditarFormaPagamento"
      size="small"
      textSucces="Atualizar"
      :item="formaPagamento"
      :disabled="!$atividades.permissoes.includes(15)"
      @excluir="exluirFormaPagamento()"
      @status="mudarStatusFormaPagamento()"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              name="nome"
              v-validate="'required'"
              label-placeholder="Nome"
              v-model="formaPagamento.nome"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "modalEditarFormaPagamento",
  props: { formaPagamento: Object },
  data() {
    return {
      modalEditarFormaPagamento: false,
      error: false,
      originalData: {}
    };
  },
  mounted() {
    this._beforeEditingCache = Object.assign({}, this.formaPagamento);
    this.originalData = this.formaPagamento;
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarFormaPagamento();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarFormaPagamento() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.$vs.loading();
          try {
            const res = await this.$http.put(
              `formasPagamento/${this.formaPagamento.id}`,
              this.formaPagamento
            );
            let logData = {
              id_colaborador: localStorage.getItem("id"),
              funcao: "atualizar",
              setor: "serviços",
              ip: window.localStorage.getItem("ip"),
              texto: "Edição da forma de pagamento N° " + res.id
            };
            await this.$logger(logData);
            this.modalEditarFormaPagamento = false;
          } catch (err) {
            const error = this.$httpErrors(err);
            this.$vs.notify(error);
          } finally {
            this.$vs.loading.close();
          }
        }
      });
    },
    async exluirFormaPagamento() {
      this.$vs.loading();
      try {
        await this.$http.delete(`formasPagamento/${this.formaPagamento.id}`);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "serviços",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão da forma de pagamento N° " + this.formaPagamento.id
        };
        await this.$logger(logData);
        this.modalEditarFormaPagamento = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async mudarStatusFormaPagamento() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(
          `formasPagamento/${this.formaPagamento.id}`,
          {
            lixeira: !this.formaPagamento.lixeira
          }
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "serviços",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status da forma de pagamento N° " + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFormaPagamento = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    cancelar() {
      Object.assign(this.formaPagamento, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarFormaPagamento = false;
    }
  },
  components: components
};
</script>

<style></style>
