<template>
  <div class="w-full">
    <vs-table noDataText="" pagination max-items="10" :data="formasPagamento">
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #B4AA99">
              Formas de Pagamento
            </h4>
          </vs-col>
          <vs-col vs-w="6" class="mb-5">
            <ModalCadastrarFormasPagamento @update="getFormasPagamento" />
          </vs-col>
        </vs-row>
      </template>
      <template slot="thead">
        <vs-th sort-key="nome">Forma de pagamento</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="data[indextr].lixeira == 1 ? 'danger' : ''"
          :key="indextr"
          v-for="(tr, indextr) in data"
        >
          <vs-td :data="data[indextr].nome">{{ data[indextr].nome }}</vs-td>

          <vs-td class="actions">
            <ModalEditarFormasPagamento
              :formaPagamento="data[indextr]"
              @update="getFormasPagamento"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="formasPagamento.length == 0">
        <div class="con-colors">
            <ul>
              <li class="danger-box">
                <h2 class="p-5" style="color: white !important">Nenhuma forma de pagamento cadastrada</h2>
              </li>
            </ul>
          </div>
      </div>
  </div>
</template>

<script>
import ModalCadastrarFormasPagamento from "./components/cadastrar.vue";
import ModalEditarFormasPagamento from "./components/editar.vue";

export default {
  data() {
    return {
      formasPagamento: []
    };
  },
  methods: {
    async getFormasPagamento() {
      this.$vs.loading();
      try {
        this.formasPagamento = await this.$http.get(`formasPagamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    }
  },
  components: {
    ModalCadastrarFormasPagamento,
    ModalEditarFormasPagamento,
  },
  mounted() {
    this.getFormasPagamento();
  }
};
</script>

<style>
.actions {
  width: 60px !important;
}
</style>
